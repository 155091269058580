// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsContactInformationScss from "./UserProjectDetailsContactInformation.scss";

var css = UserProjectDetailsContactInformationScss;

function UserProjectDetailsBusinessContactInformation(props) {
  var businessPhone = props.businessPhone;
  var businessEmail = props.businessEmail;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h4", {
                      children: "Business Contact",
                      className: css.heading
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h5", {
                                    children: props.businessName,
                                    className: css.name
                                  }),
                              className: css.nameAndTagWrapper
                            }),
                        JsxRuntime.jsx("p", {
                              children: props.headquartersAddress,
                              className: css.designationText
                            }),
                        JsxRuntime.jsx("p", {
                              children: props.businessContactName,
                              className: css.extraText
                            }),
                        JsxRuntime.jsx(Link.make, {
                              href: "mailto:" + businessEmail,
                              className: css.link,
                              children: businessEmail
                            }),
                        JsxRuntime.jsx(Link.make, {
                              href: "tel:" + businessPhone,
                              className: css.link,
                              children: businessPhone
                            })
                      ],
                      className: css.contactWrapper
                    })
              ],
              className: css.container
            });
}

var make = UserProjectDetailsBusinessContactInformation;

export {
  css ,
  make ,
}
/* css Not a pure module */
