// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TopNavbar from "../../../top-navbar/TopNavbar.res.js";
import * as AdminTopNavbar from "../../../../dashboard/common/admin-top-navbar/AdminTopNavbar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConciergeTopNavbar from "../../../../dashboard/common/concierge-top-navbar/ConciergeTopNavbar.res.js";

function ProjectTopNavbar(props) {
  var projectStickyBarData = props.projectStickyBarData;
  switch (props.viewContext) {
    case "Personal" :
        return JsxRuntime.jsx(TopNavbar.make, {
                    selectedCategory: "Projects"
                  });
    case "Admin" :
        switch (props.userRole) {
          case "Admin" :
              return JsxRuntime.jsx(AdminTopNavbar.make, {
                          selectedCategory: "Projects",
                          isAdmin: true,
                          isConcierge: projectStickyBarData.isConcierge,
                          isNotOnlyProviderMember: projectStickyBarData.isNotOnlyProviderMember
                        });
          case "Provider" :
              return JsxRuntime.jsx(AdminTopNavbar.make, {
                          selectedCategory: "Projects",
                          isAdmin: false,
                          isConcierge: projectStickyBarData.isConcierge,
                          isNotOnlyProviderMember: projectStickyBarData.isNotOnlyProviderMember
                        });
          case "User" :
              return JsxRuntime.jsx(TopNavbar.make, {
                          selectedCategory: "Projects"
                        });
          case "Visitor" :
              return null;
          case "Concierge" :
          case "Agent" :
              break;
          
        }
        if (projectStickyBarData.isConcierge) {
          return JsxRuntime.jsx(ConciergeTopNavbar.make, {
                      selectedCategory: "Projects"
                    });
        } else {
          return JsxRuntime.jsx(TopNavbar.make, {
                      selectedCategory: "Projects"
                    });
        }
    case "Visitor" :
        return null;
    
  }
}

var make = ProjectTopNavbar;

export {
  make ,
}
/* TopNavbar Not a pure module */
