// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as IconSelect from "../../../../../styleguide/icons/IconSelect.res.js";
import * as IconSelectRevert from "../../../../../styleguide/icons/IconSelectRevert.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanelScss from "./UserProjectDetailsPanel.scss";

var css = UserProjectDetailsPanelScss;

function reducer(state, action) {
  return {
          isExpanded: !state.isExpanded
        };
}

function UserProjectDetailsPanel(props) {
  var __isExpanded = props.isExpanded;
  var __bodyClassName = props.bodyClassName;
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var bodyClassName = __bodyClassName !== undefined ? __bodyClassName : "";
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  var match = React.useReducer(reducer, {
        isExpanded: isExpanded
      });
  var dispatch = match[1];
  var state = match[0];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              props.heading,
                              state.isExpanded ? JsxRuntime.jsx(IconSelectRevert.make, {
                                      size: "SM",
                                      color: "GrayText",
                                      className: css.icon
                                    }) : JsxRuntime.jsx(IconSelect.make, {
                                      size: "SM",
                                      color: "GrayText",
                                      className: css.icon
                                    })
                            ]
                          }),
                      className: css.panelHeading,
                      onClick: (function (param) {
                          dispatch("ToggleExpanded");
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: Cx.cx([
                            bodyClassName,
                            state.isExpanded ? "" : css.hidden
                          ])
                    })
              ],
              className: Cx.cx([
                    css.panelContainer,
                    className
                  ])
            });
}

var make = UserProjectDetailsPanel;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
