// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconSelectRevert(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "SelectRevert";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("g", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("polygon", {
                                points: "854.8 750 862 757.4 869.3 750 870 750.8 862 759 854 750.8",
                                transform: "translate(-854 -750)translate(862 754.5)scale(1 -1)translate(-862 -754.5)"
                              }),
                          fill: Icon.mapColor(props.color)
                        }),
                    fill: "none"
                  })
            });
}

var make = IconSelectRevert;

export {
  make ,
}
/* Icon Not a pure module */
