// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as IconPdf from "../../../../../styleguide/icons/IconPdf.res.js";
import * as Project from "../../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsDocumentsScss from "./UserProjectDetailsDocuments.scss";

var css = UserProjectDetailsDocumentsScss;

function UserProjectDetailsDocuments$DocumentRow(props) {
  var $$document = props.document;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(IconPdf.make, {
                              size: "SM",
                              color: "DarkGray",
                              className: css.icon
                            }),
                        JsxRuntime.jsx("p", {
                              children: $$document.fileName,
                              className: css.fileName
                            })
                      ],
                      className: css.fileNameAndIconWrapper
                    }),
                JsxRuntime.jsx(Button.AsLink.make, {
                      href: Routes_Project.$$Document.download(props.projectId, $$document.id),
                      size: "SM",
                      color: "Teal",
                      ghost: true,
                      buttonClassName: css.downloadButton,
                      children: "Download"
                    })
              ],
              className: css.row
            });
}

var DocumentRow = {
  make: UserProjectDetailsDocuments$DocumentRow
};

function UserProjectDetailsDocuments(props) {
  var projectId = props.projectId;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Documents",
                      className: css.documentsHeading
                    }),
                Belt_Array.map(props.documents, (function ($$document) {
                        return JsxRuntime.jsx(UserProjectDetailsDocuments$DocumentRow, {
                                    document: $$document,
                                    projectId: projectId
                                  }, "document" + Project.DocumentId.toString($$document.id));
                      }))
              ],
              className: css.container
            });
}

var make = UserProjectDetailsDocuments;

export {
  css ,
  DocumentRow ,
  make ,
}
/* css Not a pure module */
