// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tag from "../../../../../styleguide/components/Tag/Tag.res.js";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Cloudinary from "../../../../../libs/Cloudinary.res.js";
import * as UserContact from "../../../../../models/UserContact.res.js";
import * as IconUserAvatar from "../../../../../styleguide/icons/IconUserAvatar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsOwnerScss from "./UserProjectDetailsOwner.scss";

var css = UserProjectDetailsOwnerScss;

function imageUrl(publicId) {
  return Cloudinary.Url.build("Auto", {
              hd: "Dpr",
              tl: {
                hd: "Quality",
                tl: {
                  hd: {
                    TAG: "Crop",
                    _0: "Fill"
                  },
                  tl: {
                    hd: {
                      TAG: "Width",
                      _0: 120
                    },
                    tl: {
                      hd: {
                        TAG: "Height",
                        _0: 120
                      },
                      tl: /* [] */0
                    }
                  }
                }
              }
            }, Cloudinary.imageUrl(publicId));
}

var Helpers = {
  imageUrl: imageUrl
};

function UserProjectDetailsOwner(props) {
  var owner = props.owner;
  var image = owner.profilePic;
  var tmp;
  tmp = image !== undefined && image !== "" ? JsxRuntime.jsx(Picture.make, {
          src: Cloudinary.imageUrl(image),
          large: [
            60,
            60
          ],
          crop: "Fill",
          fallbackSrc: Cloudinary.imageUrl("static/profile-avatar")
        }) : JsxRuntime.jsx(IconUserAvatar.make, {
          size: "XXXL"
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h5", {
                      children: "Project Owner",
                      className: css.heading
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: tmp,
                              className: css.imageWrapper
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("span", {
                                      children: [
                                        JsxRuntime.jsx("h6", {
                                              children: owner.fullName,
                                              className: css.fullName
                                            }),
                                        props.isAgent ? JsxRuntime.jsx(Tag.make, {
                                                text: "Agent",
                                                className: css.agentTag
                                              }) : null
                                      ],
                                      className: css.nameAndTagWrapper
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: UserContact.designationText(owner.jobTitle, owner.companyName),
                                      className: css.designationText
                                    })
                              ],
                              className: css.ownerDescriptionWrapper
                            })
                      ],
                      className: css.ownerDetailsWrapper
                    })
              ],
              className: css.container
            });
}

var make = UserProjectDetailsOwner;

export {
  css ,
  Helpers ,
  make ,
}
/* css Not a pure module */
