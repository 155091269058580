// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tag from "../../../../../styleguide/components/Tag/Tag.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as UserContact from "../../../../../models/UserContact.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsContactInformationScss from "./UserProjectDetailsContactInformation.scss";

var css = UserProjectDetailsContactInformationScss;

function UserProjectDetailsContactInformation$ContactDetail(props) {
  var contact = props.contact;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h5", {
                              children: contact.fullName,
                              className: css.name
                            }),
                        contact.isPrimary ? JsxRuntime.jsx(Tag.make, {
                                text: "Primary",
                                className: css.tag
                              }) : null
                      ],
                      className: css.nameAndTagWrapper
                    }),
                JsxRuntime.jsx("p", {
                      children: UserContact.designationText(contact.jobTitle, contact.companyName),
                      className: css.designationText
                    }),
                JsxRuntime.jsx(Link.make, {
                      href: "mailto:" + contact.email,
                      className: css.link,
                      children: contact.email
                    }),
                JsxRuntime.jsx(Link.make, {
                      href: "tel:" + contact.phone,
                      className: css.link,
                      children: contact.phone
                    })
              ],
              className: css.contactWrapper
            });
}

var ContactDetail = {
  make: UserProjectDetailsContactInformation$ContactDetail
};

function UserProjectDetailsContactInformation(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h4", {
                      children: "Contact Information",
                      className: css.heading
                    }),
                Belt_Array.map(props.contacts, (function (contact) {
                        return JsxRuntime.jsx(UserProjectDetailsContactInformation$ContactDetail, {
                                    contact: contact
                                  }, contact.fullName + ("-" + contact.phone));
                      }))
              ],
              className: css.container
            });
}

var make = UserProjectDetailsContactInformation;

export {
  css ,
  ContactDetail ,
  make ,
}
/* css Not a pure module */
