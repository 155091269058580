// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Char from "../../../../../libs/Char.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as ProjectEstimatedDatesScss from "./ProjectEstimatedDates.scss";

var css = ProjectEstimatedDatesScss;

function ProjectEstimatedDates$RequiredProductRow(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h6", {
                      children: props.heading,
                      className: css.requiredProductHeading
                    }),
                JsxRuntime.jsx("p", {
                      children: Format(props.date, "dd MMM yyyy"),
                      className: Cx.cx([
                            css.requiredProductSubHeading,
                            css.requiredProductDate
                          ])
                    }),
                JsxRuntime.jsx("p", {
                      children: props.contractLength,
                      className: css.requiredProductSubHeading
                    })
              ]
            });
}

var RequiredProductRow = {
  make: ProjectEstimatedDates$RequiredProductRow
};

function ProjectEstimatedDates(props) {
  var estimatedDates = props.estimatedDates;
  var panelHeading = JsxRuntime.jsxs("div", {
        children: [
          JsxRuntime.jsx("p", {
                children: "Estimated Project Dates",
                className: css.panelHeadingText
              }),
          JsxRuntime.jsxs("div", {
                children: [
                  Format(estimatedDates.startDate, "dd MMM yyyy"),
                  JsxRuntime.jsx(Char.RightArrow.make, {
                        className: css.rightArrow
                      }),
                  Format(estimatedDates.endDate, "dd MMM yyyy")
                ],
                className: css.panelHeadingDates
              })
        ]
      });
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.container,
              heading: panelHeading,
              children: Belt_Array.mapWithIndex(estimatedDates.summaries, (function (index, config) {
                      return JsxRuntime.jsx(ProjectEstimatedDates$RequiredProductRow, {
                                  heading: config.heading,
                                  date: config.startDate,
                                  contractLength: config.contractLength
                                }, "estimated-" + String(index));
                    }))
            });
}

var make = ProjectEstimatedDates;

export {
  css ,
  RequiredProductRow ,
  make ,
}
/* css Not a pure module */
