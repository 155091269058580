// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconOk from "../../../../../styleguide/icons/IconOk.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectRequiredProducts_CheckmarkableOptionsScss from "./ProjectRequiredProducts_CheckmarkableOptions.scss";

var css = ProjectRequiredProducts_CheckmarkableOptionsScss;

function cssSide(index) {
  if (index % 2 === 0) {
    return css.propertyLeft;
  } else {
    return css.propertyRight;
  }
}

function cssShade(index, cssStart) {
  switch (cssStart) {
    case "StayLight" :
        if (index % 4 === 0) {
          return "";
        } else if ((index - 1 | 0) % 4 === 0 || index % 2 !== 0) {
          return css.stayShaded;
        } else {
          return css.switchShaded;
        }
    case "SwitchLight" :
        if (index % 4 === 0) {
          return css.switchLight;
        } else if ((index - 1 | 0) % 4 === 0) {
          return "";
        } else if (index % 2 === 0) {
          return css.stayShaded;
        } else {
          return css.switchShaded;
        }
    case "StayShaded" :
        if (index % 4 === 0) {
          return css.stayShaded;
        } else if ((index - 1 | 0) % 4 === 0) {
          return css.switchShaded;
        } else if (index % 2 === 0) {
          return css.switchLight;
        } else {
          return "";
        }
    case "SwitchShaded" :
        if (index % 4 === 0) {
          return css.switchShaded;
        } else if ((index - 1 | 0) % 4 === 0) {
          return css.switchLight;
        } else if (index % 2 === 0) {
          return "";
        } else {
          return css.stayShaded;
        }
    
  }
}

function ProjectRequiredProducts_CheckmarkableOptions(props) {
  var __cssStart = props.cssStart;
  var options = props.options;
  var cssStart = __cssStart !== undefined ? __cssStart : "StayShaded";
  if (options.length !== 0) {
    return Belt_Array.mapWithIndex(options, (function (index, optionValue) {
                  return JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(IconOk.make, {
                                            size: "XS",
                                            color: "Teal",
                                            className: css.tickmarkIcon
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: optionValue,
                                            className: css.productServiceLabel
                                          })
                                    ],
                                    className: css.productsServiceWrapper
                                  }, String(index) + optionValue),
                              className: Cx.cx([
                                    cssSide(index),
                                    cssShade(index, cssStart)
                                  ])
                            }, String(index));
                }));
  } else {
    return null;
  }
}

var make = ProjectRequiredProducts_CheckmarkableOptions;

export {
  css ,
  cssSide ,
  cssShade ,
  make ,
}
/* css Not a pure module */
