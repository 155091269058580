// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../../../libs/ID.res.js";
import * as Tag from "../../../../../styleguide/components/Tag/Tag.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Role from "../../../../../models/Role.res.js";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../../../../../libs/Cloudinary.res.js";
import * as IconUserAvatar from "../../../../../styleguide/icons/IconUserAvatar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsContactInformationScss from "./UserProjectDetailsContactInformation.scss";

var css = UserProjectDetailsContactInformationScss;

function UserProjectConciergesInformation$ConciergeDetail(props) {
  var concierge = props.concierge;
  var image = concierge.profilePic;
  var tmp = image === "" ? JsxRuntime.jsx(IconUserAvatar.make, {
          size: "XXXL"
        }) : JsxRuntime.jsx(Picture.make, {
          src: Cloudinary.imageUrl(image),
          large: [
            60,
            60
          ],
          crop: "Fill",
          imageClassName: css.profilePic,
          fallbackSrc: Cloudinary.imageUrl("static/profile-avatar")
        });
  var jobTitle = concierge.jobTitle;
  var tmp$1 = jobTitle === "" ? null : JsxRuntime.jsx("p", {
          children: jobTitle,
          className: css.designationText
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: css.imageWrapper
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h5", {
                                      children: concierge.firstName + (" " + concierge.lastName),
                                      className: css.name
                                    }),
                                JsxRuntime.jsx(Tag.make, {
                                      text: Role.toCapital(concierge.role),
                                      className: css.roleTag
                                    })
                              ],
                              className: css.nameAndTagWrapper
                            }),
                        tmp$1,
                        JsxRuntime.jsx(Link.make, {
                              href: "mailto:" + concierge.email,
                              className: css.link,
                              children: concierge.email
                            }),
                        JsxRuntime.jsx(Link.make, {
                              href: "tel:" + concierge.phoneNumber,
                              className: css.link,
                              children: concierge.phoneNumber
                            })
                      ],
                      className: css.conciergeData
                    })
              ],
              className: css.conciergeWrapper
            });
}

var ConciergeDetail = {
  make: UserProjectConciergesInformation$ConciergeDetail
};

function UserProjectConciergesInformation(props) {
  var concierges = props.concierges;
  if (concierges.length !== 0) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("h4", {
                        children: "Datacenters.com Concierge",
                        className: Cx.cx([
                              css.heading,
                              css.conciergeHeading
                            ])
                      }),
                  JsxRuntime.jsx("div", {
                        children: "Feel free to reach out to the concierge if you have any questions.",
                        className: css.conciergeInfo
                      }),
                  Belt_Array.map(concierges, (function (concierge) {
                          return JsxRuntime.jsx(UserProjectConciergesInformation$ConciergeDetail, {
                                      concierge: concierge
                                    }, ID.toString(concierge.id));
                        }))
                ],
                className: css.container
              });
  } else {
    return null;
  }
}

var make = UserProjectConciergesInformation;

export {
  css ,
  ConciergeDetail ,
  make ,
}
/* css Not a pure module */
